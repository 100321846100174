
import { Injectable } from '@angular/core';
import {NativeDateAdapter} from '@angular/material/core';
import {NgxMatNativeDateAdapter} from "@angular-material-components/datetime-picker";


@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    override getFirstDayOfWeek(): number {
        return 1;
    }
}

@Injectable()
export class CustomNgxDateAdapter extends NgxMatNativeDateAdapter {
    override getFirstDayOfWeek(): number {
        return 1;
    }
}
