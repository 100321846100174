import {Pipe, PipeTransform} from '@angular/core';
import {TitleCasePipe} from '@angular/common';
import {isString} from 'lodash';

@Pipe({
    name: 'customTitleCase'
})
export class CustomTitleCasePipe extends TitleCasePipe implements PipeTransform {

    transform(value: string, uppercase?: boolean): any {
        if (uppercase) {
            return isString(value) ? value?.toUpperCase() : value;
        }
        return value;
    }

}
