import { Pipe, PipeTransform } from '@angular/core';
import {isString} from "lodash";

@Pipe({
  name: 'removeUnderscores',
  pure: true
})
export class RemoveUnderscoresPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    return  isString(value) ? value?.replace(/_/g, ' ')?.trim() : value;
  }

}
